import React, { Children } from "react";
import Helmet from "react-helmet";

import { metaPropTypes } from "config/propTypes";
import StorageLogo from '../../../assets/images/logos/storage-dot-com.png'
import StorageSocialLogo from '../../../assets/images/logos/storage-social-logo.png'


export default function Head({
	title,
	description,
	canonical,
	links,
	noIndex
}) {
	/**
	 * FYI! We _always_ show `noindex` in lower environments to keep them
	 * out of search engines. If you're going crazy debugging a noindex issue,
	 * comment out the line in `server/lib/render/page.js` that sets
	 * this tag in preprod.
	 */
	return (
		<Helmet>
			<title key="title">{title}</title>
			{!!description && (
				<meta
					key="description"
					name="description"
					content={description}
				/>
			)}
			{!!canonical && (
				<link
					rel="canonical"
					href={canonical}
				/>
			)}
			{!!noIndex && (
				<meta
					key="robots"
					name="robots"
					content="noindex"
				/>
			)}
			{Children.toArray(links.map((link) => <link {...link} />))}
			{/* TODO: check if react-helmet fixed this bug so we can import the favicon */}
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href="/apple-touch-icon.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href="/favicon-32x32.png"
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href="/favicon-16x16.png"
			/>
			<link
				rel="manifest"
				href="/site.webmanifest"
			/>
			<link
				rel="mask-icon"
				href="/safari-pinned-tab.svg"
				color="#1c72e7"
			/>
			<meta
				name="msapplication-TileColor"
				content="#1c72e7"
			/>
			<meta
				name="theme-color"
				content="#1c72e7"
				media="(prefers-color-scheme: light)"
			/>
			<meta
				name="theme-color"
				content="#1c72e7"
				media="(prefers-color-scheme: dark)"
			/>
			<meta
				name="referrer"
				content="origin"
			/>
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description}/>
			<meta property="og:type" content="website" />
			<meta property="og:image" content={StorageSocialLogo} />
			<meta property="og:url" content={canonical} />
			<meta name="twitter:card" content="svg twitter" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={StorageLogo} />
		</Helmet>
	);
}

Head.defaultProps = {
	title: "",
	description: "",
	links: []
};

Head.propTypes = metaPropTypes;
